/* eslint-disable camelcase */
import { defineStore, acceptHMRUpdate } from 'pinia';
import { OnboardingUser, Agreements, OnboardingResponse } from '@graphql/types/onboarding';
import { DocumentContext } from '@graphql/types/document';
import { ONBOARDING_QUERY } from '@graphql/queries/onboarding';
import { OperationResult, CombinedError } from '@urql/core';
import { useUrqlStore } from '@stores/useUrqlStore';

interface SignStoreState {
  user: OnboardingUser | null;
  agreements: Agreements | null;
  documentContext: DocumentContext[] | null;
  debitUrl: string | null;
  signing_id: string;
  token: string;
  step: number;
  signedState: string | null;
  mandateState: string | null;
  error: CombinedError | undefined;
  loading: boolean;
  manualSign: boolean;
  mandateLoading: boolean;
  isWrongIban: boolean;
  correctIban: string;
  isUploading: boolean;
}

export const useSignStore = defineStore('signStore', {
  state: () : SignStoreState => ({
    user: null,
    signedState: null,
    mandateState: null,
    agreements: null,
    documentContext: null,
    debitUrl: null,
    signing_id: '',
    token: '',
    step: 0,
    error: undefined,
    loading: true,
    manualSign: false,
    mandateLoading: false,
    isWrongIban: false,
    correctIban: '',
    isUploading: false,
  }),
  share: {
    enable: false,
  },
  actions: {
    resetState() {
      this.user = null;
      this.signedState = null;
      this.mandateState = null;
      this.agreements = null;
      this.documentContext = null;
      this.debitUrl = null;
      this.signing_id = '';
      this.token = '';
      this.step = 0;
      this.error = undefined;
      this.loading = true;
      this.manualSign = false;
      this.mandateLoading = false;
      this.isUploading = false;
    },
    store(data: OnboardingResponse) {
      this.user = data.onboarding.me;
      this.signedState = data.onboarding.status;
      this.debitUrl = data.onboarding.direct_debit_authorization_url;
      this.mandateState = data.onboarding.direct_debit_authorized_at;
      this.agreements = data.onboarding.agreements;
      this.documentContext = data.onboarding.documentContext;
    },
    setLoading(isLoading: boolean) {
      this.loading = isLoading;
    },
    setMandateLoading(isLoading: boolean) {
      this.mandateLoading = isLoading;
    },
    storeTokens(signing_id: string, token: string) {
      this.signing_id = signing_id;
      this.token = token;
    },
    goToNextStep() {
      if (this.debitUrl !== null) {
        this.step = 2;
      } else {
        this.step = 3;
      }
    },
    directDebitNextStep() {
      this.step = 3;
    },
    directDebitFailed(iban: string) {
      this.isWrongIban = true;
      this.step = 2;
      this.correctIban = iban;
    },
    goToCorrectStepFromWelcome(isInitial = false) {
      const documentsArray = this.documentContext?.[0].documentOwners?.[0].items;
      const amountOfDocumentsToSign = this.documentContext?.[0].documentOwners?.[0].items.length ?? [];

      if (isInitial) {
        this.step = 0;
      } else if (this.signedState !== 'SIGNED' || documentsArray?.length !== amountOfDocumentsToSign) {
        this.step = 1;
      } else if (this.debitUrl !== null && this.mandateState === null) {
        this.step = 2;
      } else {
        this.step = 3;
      }
    },
    setError(error: CombinedError | undefined) {
      this.error = error;
    },
    setDownloadUrlOnTemplate(documentIndex: number, link: string) {
      if (this.documentContext?.[0].documentOwners?.[0]) {
        this.documentContext[0].documentOwners[0].items[documentIndex].template.absolute_url = link;
      }
    },
    setIsUploading(value: boolean) {
      this.isUploading = value;
    },
    getUser(signing_id: string, token: string): Promise<OnboardingResponse> {
      this.loading = true;
      return new Promise(async resolve => {
        const { gqlClient } = toRefs(useUrqlStore());

        await gqlClient.value
          .query<OnboardingResponse>(ONBOARDING_QUERY, { signing_id, token })
          .toPromise()
          .then((result: OperationResult<OnboardingResponse>) => {
            if (result.data?.onboarding) {
              this.store(result.data);
              this.storeTokens(signing_id, token);
              this.goToCorrectStepFromWelcome();
              resolve(result.data);
            } else {
              showError({ message: 'Link ongeldig', statusCode: 404 });
            }
          })
          .catch((error: CombinedError) => {
            this.setError(error);
            showError({ message: 'Link ongeldig', statusCode: 404 });
          });
        this.loading = false;
      });
    },
  },
  getters: {
    documents(state: SignStoreState) {
      return state.documentContext?.[0].documentOwners?.[0].items;
    },
  },
  persistedState: {
    persist: false,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSignStore, import.meta.hot));
}
